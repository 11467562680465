



























import { Component, Vue, Watch } from 'vue-property-decorator'
import FinancialAccounting from '../../components/core/accounting/financial/FinancialAccounting.vue'
import { AccountingFinancial } from '@/model/AccountingFinancial'
import utils from '../../utils/Utils'

@Component({
  components: { FinancialAccounting },
})
export default class ViewAccountings extends Vue {
  projectId!: number
  year = utils.nowToMoment().year()

  get isAdmin(): boolean {
    return this.$store.getters.isAdmin
  }

  get accountings(): AccountingFinancial[] {
    return this.$store.getters.accountings.sort(
      (a: any, b: any) => a.year - b.year,
    )
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    const id = +this.$route.params.id
    this.projectId = id
    this.$store.dispatch('getAccountings', this.projectId)
  }

  newAccounting(year: number) {
    if (!year) {
      return this.$store.dispatch('toaster/toast', {
        message: `L'année saisie n'est pas valide`,
        type: 'warning',
      })
    }

    this.$store
      .dispatch('addAccounting', { projectId: this.projectId, year: this.year })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        if (json.success) {
          this.year++
        }
      })
  }

  goToYear(year: number) {
    this.$router.push(`accounting/${year}`)
  }
}
